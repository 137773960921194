import React from "react"

const ErrorPage = () => {
  return (
    <>
      <h1>ERROR PAGE, 404</h1>
    </>
  )
}

export default ErrorPage
